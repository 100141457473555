.verticalStep {
}

.verticalStepWithLine {
  position: relative;

  &::before {
    content: ' ';
    position: absolute;
    top: 37px;
    left: 22px;
    width: 2px;
    height: 100%;
    background-color: #09395f;
  }
}

.verticalStepContent {
  position: relative;
  top: -11px;
  display: flex;

  align-items: center;
  justify-content: space-around;

  width: 46px;
  height: 46px;

  border-radius: 50%;
  box-shadow: 0 0 0 2px #09395f;
  background: white;

  font-family: Roboto, sans-serif;
  font-style: normal;
  font-weight: 900;
  font-size: 16px;
  line-height: 46px;
}
