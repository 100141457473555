.pageContainer {
  padding: 20px;
  width: 100%;
  margin-top: 40px;
}
.pageTitle {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 26px;
  text-transform: uppercase;
}
.card {
  margin-top: 50px;
  margin-bottom: 20px;
  .cardContainer {
    margin-bottom: 15px;
  }
  .cardContent {
    display: flex;
  }
  .cardMargin {
    margin-left: 30px;
  }
  .cardRow {
    margin-top: 8px;
  }
  .cardText {
    font-weight: 700;
  }
  .cardColumn {
    display: flex;
    margin-top: 40px;
  }
  .cardInput {
    margin-left: 20px;
    width: 380px;
  }
  .cardButton {
    width: 7rem;
    .cardButtonText {
      color: #232424;
    }
  }
}

.msgButton {
  margin: 0;
  width: 105px;
  height: 39px;

  text-transform: none;
  letter-spacing: 0px;

  color: #ffffff;
  --background: #0a3860;
}

.customHr {
  margin: 16px 0;
  border-top: 1px solid #000000;
}
