.modalOverlay {
  display: flex;
  align-items: center;
  justify-content: center;

  position: absolute;
  top: 30px;
  left: 0;

  width: 100%;
  height: 100%;

  z-index: 1;
}

.modalContent {
  position: relative;
  width: fit-content;
  height: fit-content;
  max-height: 100%;
  overflow-y: auto;

  border: 1px solid #c6cacc;
  border-radius: 8px;
  background-color: #ffffff;
}

.closeCross {
  position: absolute;
  top: 5px;
  right: 13px;
  padding: 13px;

  font-size: 38px;
  color: #000000;
  cursor: pointer;
}

.wrapper {
  max-width: 577px;
  padding: 42px 38px 0 52px;
}

.closeCross {
  position: absolute;
  top: 4px;
  right: 17px;
  padding: 24px;

  font-size: 38px;
  color: #000000;
  cursor: pointer;
}

.title {
  font-size: 24px;
  line-height: 28px;
  font-weight: 700;
  color: black;
}

.step {
  display: flex;
  flex-direction: column;
  column-gap: 16px;
}

.requiredMark {
  margin-right: 4px;
  color: red;
}

.uploadListTitle {
  color: #1b1b1b;
  font-family: Roboto;
  font-size: 16px;
  line-height: 18px;
}

.uploadListInfo {
  margin-top: 4px;
  margin-bottom: 27px;

  color: #6d6d6d;
  font-family: Roboto;
  font-size: 12px;
  line-height: 12px;
}


.separator {
  margin: 19px 7px 0;

  height: 1px;
  background: #c4c4c4;
}

// buttons
.buttons {
  display: flex;
  justify-content: space-between;
  margin: 23px 6px 0;
}

.cancelButton,
.submitButton {
  color: #000000;
  text-transform: none;
  letter-spacing: 0;
  --background: white;

  --border-width: 2px;
  --border-style: solid;
  --border-color: #000000;
  --box-shadow: none;
}

.cancelButton {
  width: 97px;
  --border-radius: 8px;
}

.submitButton {
  width: 198px;
  color: white;
  background-color: #0a3860;
  --border-radius: 8px;
  --border-color: #0a3860;
  border-radius: 8px;
  --background: #0a3860;
}
