.modalOverlay {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  bottom: 10%;
  height: 80%;
  z-index: 1;
}

.modalContent {
  width: 733px;
  height: fit-content;
  max-height: 100%;
  overflow-y: auto;

  border: 1px solid #c6cacc;
  border-radius: 8px;
  background-color: #ffffff;

  padding: 30px 60px;
}

.title {
  margin-top: 35px;
  margin-left: 10px;
  font-style: normal;
  font-weight: 400;
  font-size: 24px;
  line-height: 28px;
  display: flex;
  align-items: center;

  color: #666666;
}

.subTitle {
  margin-top: 20px;
  margin-left: 10px;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;

  color: #999999;
}

.button {
  margin: 20px;
  width: 550px;
  height: 39px;

  text-transform: none;
  letter-spacing: 0px;

  color: #ffffff;
  --background: #37d6ba;
}

.dropdown {
  width: 100%;
  z-index: 999;
  margin-top: 2px;
  text-align: left;
  border-bottom: 1px solid #dedede;
}

.clearButtonContainer {
  position: absolute;
  top: 10px;
  right: 10px;
  z-index: 99;
  padding: 10px 20px;
}

.clearButton {
  width: fit-content;
}

.closeIcon {
  width: 20px;
  height: 20px;
}
