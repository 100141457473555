.toolbarTitles {
  font-size: 16px;
}
.toolbarProfile {
  height: 5%;
}
.toolbarLogo {
  margin-left: 10px;
  width: 150px;
}
.toolbarOrganization {
  font-family: Arial;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 18px;
  letter-spacing: 0em;
  text-align: right;
  color: rgb(38, 38, 38);
}

.toolbarUserInfo {
  font-family: Arial;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px;
  letter-spacing: 0em;
  text-align: right;
  color: rgb(178, 178, 178);
}
