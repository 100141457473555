@tailwind base;
@tailwind components;
@tailwind utilities;

#searchBar {
  --box-shadow: none;
  --margin-right: 0;
}
.app {
  background: #e5e5e5;
  color: #43425d;
}

.sidebar {
  display: flex;
  flex: 0 0 auto;
  position: relative;
}

.content {
  display: flex
}

@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500;700&display=swap');

body {
  font-family: 'Roboto', sans-serif;
}

.d-flex {
  display: flex !important;
}
.text-right {
  text-align: right;
}
.ion-content,
.app-bg {
  background: #f4f4f4;
}
/* ======= Module Title ====== */
.module-title {
  font-size: 17px;
  color: #999;
}
.module-title.text-small {
  font-size: 12px;
}

.status-bullet {
  width: 12px;
  height: 12px;
  overflow: hidden;
  float: right;
  border-radius: 50%;
  margin-left: 5px;
}
.status-bullet.inprogress {
  background: #ffb74d;
}
.status-bullet.open {
  background: #37d6ba;
}

.app-content {
  padding-top: 30px;
}

.ReactModal__Body--open ion-router-outlet {
  filter: brightness(0.4) blur(15px);
}

.ReactModal__Body--open ion-app {
  background: gray;
}

ion-backdrop {
  background-color: #000000;
  opacity: 0.01;
}

ion-fab-button {
  --background: #09395f;
}

ion-select {
  margin-top: 5px;
}

:root {
  --ion-color-form-create-button: #dcf4fe;
  --ion-color-form-create-button-rgb: 220, 244, 254;
  --ion-color-form-create-button-contrast: #000000;
  --ion-color-form-create-button-contrast-rgb: 0, 0, 0;
  --ion-color-form-create-button-shade: #c2d7e0;
  --ion-color-form-create-button-tint: #e0f5fe;

  --ion-color-base-blue: #367bf5;
  --ion-color-base-blue-rgb: 54, 123, 245;
  --ion-color-base-blue-contrast: #ffffff;
  --ion-color-base-blue-contrast-rgb: 255, 255, 255;
  --ion-color-base-blue-shade: #306cd8;
  --ion-color-base-blue-tint: #4a88f6;
}

.ion-color-form-create-button {
  --ion-color-base: var(--ion-color-form-create-button);
  --ion-color-base-rgb: var(--ion-color-form-create-button-rgb);
  --ion-color-contrast: var(--ion-color-form-create-button-contrast);
  --ion-color-contrast-rgb: var(--ion-color-form-create-button-contrast-rgb);
  --ion-color-shade: var(--ion-color-form-create-button-shade);
  --ion-color-tint: var(--ion-color-form-create-button-tint);
}

.ion-color-base-blue {
  --ion-color-base: var(--ion-color-base-blue);
  --ion-color-base-rgb: var(--ion-color-base-blue-rgb);
  --ion-color-contrast: var(--ion-color-base-blue-contrast);
  --ion-color-contrast-rgb: var(--ion-color-base-blue-contrast-rgb);
  --ion-color-shade: var(--ion-color-base-blue-shade);
  --ion-color-tint: var(--ion-color-base-blue-tint);
}
