.modalOverlay {
  display: flex;
  align-items: center;
  justify-content: center;

  position: absolute;
  top: 30px;
  left: 0;

  width: 100%;
  height: 100%;

  z-index: 1;
}

.modalContent {
  width: fit-content;
  max-width: 362px;
  height: fit-content;
  max-height: 100%;
  overflow-y: auto;
  text-align: center;
  padding: 24px 22px 0px;

  border-radius: 8px;
  background-color: #ffffff;

  font-family: Arial, Verdana, Tahoma, sans-serif;
}

.title {
  color: #000000;
  font-size: 24px;
  line-height: 28px;
  font-weight: 700;
}

.explanatoryText {
  margin-top: 32px;
  margin-bottom: 26px;
  color: #5e6366;
  font-size: 20px;
  line-height: 23px;
}

.buttons {
  border-top: 2px solid #eee;
}

.closeButton {
  width: 173px;
  color: #367bf5;
  text-transform: none;
  --background: white;
}
