.form {
  margin-top: 0;
  width: 100%;
  overflow-x: hidden;
}

.button {
  width: 100%;
  height: 39px;

  text-transform: none;
  letter-spacing: 0px;

  color: #ffffff;
  --background: #37d6ba;
}
