.card {
  position: absolute;
  top: 35%;
  z-index: 1;
  border: solid 1px #0a3860;
  width: 40%;
}

.cardHeader {
  background-color: #09395f;
  color: white;
  font-size: 24px;
  font-weight: bold;
  padding: 0 10% 0 10%;
  margin-bottom: 3%;
}

.cardContent {
  padding: 0% 10% 0 10%;
}

.doneButton,
.cancelButton,
.submitButton {
  color: #000000;
  text-transform: none;
  letter-spacing: 0;
  width: 100%;
  --background: white;

  --border-width: 2px;
  --border-style: solid;
  --border-color: #000000;
  --box-shadow: none;
}
