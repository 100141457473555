.fileUplaodContainer {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: 130px;
  margin: 20px 0 40px 0;
  width: 100%;
  background: rgba(220, 244, 254, 0.25);
  border: 1px dashed rgba(9, 57, 95, 0.5);
  border-radius: 24px;
}

.fileUploadInput {
  font-size: 70px;
  position: absolute;
  left: 0;
  top: 0;
  opacity: 0;
}

.circle {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 60px;
  height: 60px;
  background: #dcf4fe;
  border: 1px dashed #0a3860;
  box-sizing: border-box;
  border-radius: 50%;
}

.icon {
  text-align: center;
  height: 30px;
  width: 30px;
}

.logoplaceholderLabel {
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  display: flex;
  align-items: center;
  margin-top: 10px;
  color: #09395f;
}

.uploadedImage {
  height: 100px;
}
