.modalOverlay {
    display: flex;
    align-items: center;
    justify-content: center;

    position: absolute;
    top: 30px;
    left: 0;

    width: 100%;
    height: 100%;

    z-index: 1;
}

.modalContent {
    position: relative;
    max-width: 1031px;
    min-width: 942px;
    padding: 40px 43px 27px 50px;
    width: fit-content;
    height: fit-content;
    max-height: 100%;
    overflow-y: auto;

    border: 1px solid rgba(120, 120, 120, 0.1);
    border-radius: 8px;
    background-color: #ffffff;
    outline: none;
    -webkit-background-clip: padding-box;
    background-clip: padding-box;

    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 18px;

    ion-button {
        --box-shadow: none;
    }
}

.closeCross {
    position: absolute;
    top: 4px;
    right: 17px;
    padding: 24px;

    font-size: 38px;
    color: #000000;
    cursor: pointer;
}

.title {
    margin: 0;
    color: #000000;
    font-size: 24px;
    line-height: 28px;
    letter-spacing: 0.75px;
}

.instruction {
    width: 781px;
    margin-top: 16px;

    font-family: Arial;
    font-size: 20px;
    line-height: 23px;
    color: #5e6366;
}

.contentWrapper {
    display: flex;
    column-gap: 45px;
}

.contentLeft,
.contentRight {
    flex-basis: 50%;
}

.sectionTitle {
    margin: 0;

    font-family: Roboto;
    font-weight: bold;
    font-size: 14px;
    line-height: 18px;
    color: #000000;
}

.avatar {
    width: 32px;
    height: 32px;
    border-radius: 50%;
}

// left

.requestDescriptionWrapper {
    margin-top: 40px;
}

.requestDescription {
    margin-top: 8px;
    margin-left: 3px;
}

.author {
    margin-top: 5px;
    margin-right: 29px;
    text-align: end;

    color: #6d6d6d;
    font-size: 12px;
    line-height: 12px;
}

.commentsWrapper {
    margin-top: 36px;

    .sectionTitle {
        margin-bottom: 12px;
    }

    .avatar {
        margin-left: 2px;
        margin-top: 4px;
    }
}

.comments {
    max-height: 400px;
    overflow: hidden;
    overflow-y: auto;

    cursor: all-scroll;

    &:active {
        cursor: text;
    }
}

.comment {
    display: flex;
    width: 400px;

    & + & {
        margin-top: 30px;
        margin-bottom: 15px;
    }

    .author {
        margin: 5px 35px 0;
    }
}

.profileIcon {
    flex: 1;
}

.commentBody {
    flex: 9;
}

.commentText {
    margin: 2px 0 0 0;
}

.newComment {
    display: flex;
    margin-top: 28px;

    & ion-icon {
        align-self: flex-end;
        margin-bottom: 0px;
        margin-right: -12px;
    }
}

.newCommentText {
    width: 384px;
    margin-top: 3px;
    margin-left: 16px;

    border: 1px solid #dadada;
    border-radius: 6px;
}

.newCommentLabel {
    overflow: visible !important;
}

.noteIcon {
    cursor: pointer;
    padding: 12px;

    &:hover {
        color: #000000;
    }
}

.notesActions {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    span {
        width: 75%;
        font-size: 12px;
    }
}

// right

.statusButton {
    margin-top: 42px;
    width: 191px;

    text-transform: none;
    font-family: Arial;
    font-weight: bold;
    font-size: 18px;
    line-height: 21px;

    border-radius: 7px;

    color: #ffffff;
    --background: #37d6ba;
    letter-spacing: normal;

    // allows showing "title" attribute on "hover" while being "disabled"
    pointer-events: all;

    & ion-icon {
        color: #ffffff;
        font-size: 16px;
    }
}

.shevron {
    margin-left: 22px;
}

.patient {
    display: flex;
    align-items: center;
    margin-top: 16px;

    .sectionTitle {
        flex-basis: 160px;
    }

    .avatar {
        margin-right: 12px;
    }

    ion-router-link {
        color: inherit;
        text-decoration: underline;
        cursor: pointer;
    }
}

.employee {
    display: flex;
    align-items: center;

    margin-top: 35px;

    .sectionTitle {
        flex-basis: 160px;
    }

    .avatar {
        margin-right: 12px;
    }
}

.employeeSelect {
    display: flex;
    align-items: center;

    ion-router-link {
        color: inherit;
        text-decoration: underline;
        cursor: pointer;
    }

    ion-icon {
        padding: 12px;

        font-size: 12px;
        color: #999999;

        cursor: pointer;
    }
}

.organization,
.service {
    display: flex;
    align-items: center;

    margin-top: 45px;

    .sectionTitle {
        flex-basis: 181px;
        flex-wrap: wrap;
    }
}

.separator {
    width: 100%;
    height: 1px;

    margin-top: 42px;
    margin-bottom: 16px;

    background-color: #e8e8e8;
}

.ticketInfo {
    font-size: 14px;
    line-height: 38px;
    color: #898a8d;
}

.ticketInfoRowKey {
    min-width: 60px;
    font-weight: bold;
    display: inline-block;
}

.buttonsRow {
    margin-top: 40px;
    text-align: end;
}

.cancelButton {
    margin: 0;
    width: 148px;
    height: 39px;

    text-transform: none;

    letter-spacing: 0px;
}

.submitButton {
    margin: 0 0 0 20px;
    width: 278px;
    height: 39px;

    text-transform: none;
    letter-spacing: 0px;

    color: #ffffff;
    --background: #0a3860;
}

.errorMessage {
    color: #ff9494;
}

.fileLink {
    text-decoration: underline;
    cursor: pointer;
    color: blue;
}

.msgReview {
    text-decoration: underline;
    cursor: pointer;
    color: #397cf4;
}

.msgReviewIcon {
    cursor: pointer;
    color: #000000;
    position: absolute;
}
