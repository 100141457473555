.page {
  display: flex;
  width: 100%;
  height: 100%;
  background-color: #f5f5f5;

  padding: 20px 42px 57px 34px;
}

.pageTitle {
  font-family: 'Lato' sans-serif;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  margin: 20px 35px 10px 35px;
}

.section {
  width: 481px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  // flex-basis: 481px;
  height: fit-content;
  min-height: 906px;
  border-radius: 8px;
  background: #ffffff;
  box-shadow: 0px 12px 26px 0px rgba(16, 30, 115, 0.06);
}

.section2 {
  width: 100%;
  margin-left: 45px;
}

.sectionTitle {
  font-family: Lato, sans-serif;
  font-size: 20px;
  font-weight: 700;
  line-height: 26px;
  letter-spacing: 0.2px;

  color: #25282b;
}

.userInformationSectionTitle {
  padding: 17px 29px 12px;
}

.userIdentityWrapper {
  display: flex;
  padding: 18px 17px 23px 52px;

  background: #f9f9f9;
}

.userAvatar {
  min-width: 82px;
  min-height: 82px;
}

.userIdentity {
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  justify-content: center;
  margin-left: 12px;
}

.userName {
  margin-top: 8px;

  font-family: Arial, Helvetica, sans-serif;
  font-weight: bold;
  font-size: 21px;
  line-height: 21px;
}

.userType {
  margin-top: 1px;

  font-family: Arial, Helvetica, sans-serif;
  font-weight: normal;
  font-size: 17px;
  line-height: 21px;
}

.editProfileWrapper {
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;

  font-family: Arial, Helvetica, sans-serif;
  font-size: 12px;
  font-variant: normal;
}

ion-icon[name='pencil'].editProfileIcon {
  margin-right: 7px;

  align-self: center;
  background-image: url(./icons/pencil.svg);
  background-repeat: no-repeat;
  background-position: center;
  background-size: 18px;
  width: 18px;
  height: 18px;
}

.editProfileButton {
  height: 46px;
  margin: 0;
  padding: 0;
  font-size: 12px;
  text-transform: none;
}

.userContacts {
  width: 481px;
  margin: 10px 52px;
  line-height: 20px;
}

.userDetails {
  width: 450px;
  margin: 30px 52px 10px 52px;
  line-height: 20px;
}

.userContactRow {
  display: flex;
  & + & {
    margin-top: 22px;
  }
}

.userRoleRow {
  display: flex;
  align-items: center;
  margin: 10px 0 10px 0;
  & + & {
    margin-top: 22px;
  }
}

.userContactKey {
  flex: 2;
  font-family: Arial, Helvetica, sans-serif;
  font-weight: bold;
  font-size: 14px;
  line-height: 24px;
  height: 100%;
}

.userContactValue {
  flex: 8;
  padding-left: 5px;
  font-family: Arial, Helvetica, sans-serif;
  font-weight: normal;
  font-size: 14px;
  line-height: 24px;
}

.spacer {
  padding: 32px;
}

.userAction {
  padding: 52px;
  cursor: pointer;
}

.sectionHeader {
  margin-top: 20px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #000;
}

.servicesContainer {
  font-family: Arial, Helvetica, sans-serif;
  font-weight: bold;
  font-size: 14px;
  line-height: 24px;
  height: 100%;
}

.addServiceContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 20px;
  font-size: 14px;
  cursor: pointer;

  p {
    margin-left: 5px;
    font-weight: bold;
  }
}

.addIcon {
  font-size: 14px;
}

.serviceListItem {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin: -2px 10px;
  width: 100%;
}

.serviceName {
  font-family: Arial, Helvetica, sans-serif;
  font-size: 14px;
  margin-right: 20px;
  flex: 8;
}

.delete {
  flex: 2;
  margin: 0;
  margin-left: 20px;
}
