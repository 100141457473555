.modalOverlay {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  bottom: 10%;
  height: 80%;
  z-index: 1;
}

.modalContent {
  width: fit-content;
  height: fit-content;
  max-height: 100%;
  overflow-y: auto;

  border: 1px solid #c6cacc;
  border-radius: 8px;
  background-color: #ffffff;
}

// common

.itemError {
  --border-color: red;
  --highlight-color-focused: red;
}

ion-label {
  --color: #5e6366 !important;
}

.wrapper {
  max-width: 556px;
  padding: 32px 38px 0 52px;
  margin-right: -8px;
  margin-left: -8px;
}

.modalContent {
  position: relative;
}

.closeCross {
  position: absolute;
  top: 0;
  right: 0;
  padding: 24px;

  font-size: 38px;
  color: #000000;
  cursor: pointer;
}

.title {
  margin-bottom: 16px;

  font-size: 24px;
  line-height: 28px;
  font-weight: 700;
}

// content

.row {
  display: flex;
  column-gap: 13px;
  flex-wrap: wrap;
  width: 100%;
}

.firstName,
.lastName,
.phoneNumber,
.dateOfBirth,
.addressLine1,
.addressLine2,
.city,
.state,
.zipCode {
  min-width: 220px;
  flex-grow: 1;
}

.language,
.email {
  width: 100%;
}

.checkboxLabel {
  padding-left: 16px;
}

// buttons

.buttons {
  display: flex;
  justify-content: space-between;

  margin-top: 28px;
}

.cancelButton,
.submitButton {
  color: #000000;
  --background: white;

  --border-width: 2px;
  --border-style: solid;
  --border-color: #000000;
  --box-shadow: none;
}

.cancelButton {
  width: 178px;
}

.submitButton {
  width: 256px;
}

input:focus {
  outline: none;
}
