.modalOverlay {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  bottom: 10%;
  height: 80%;
  z-index: 1;
}

.modalContent {
  width: fit-content;
  height: fit-content;
  max-height: 100%;
  overflow-y: auto;

  border: 1px solid #c6cacc;
  border-radius: 8px;
  background-color: #ffffff;
}

.standartIcon {
  align-self: flex-end;
}

.wrapper {
  max-width: 556px;
  padding: 24px 52px 0 52px;
}

.closeCross {
  position: absolute;
  top: 4px;
  right: 17px;
  padding: 24px;

  font-size: 38px;
  color: #000000;
  cursor: pointer;
}

.smsTitle {
  font-size: 24px;
  line-height: 28px;
  font-weight: 700;
  padding-top: 40px;
}

.smsWrapper {
  height: 400px;
  overflow-y: auto;
  margin: 32px 0;
}

.profileImage {
  font-size: 32px;
}

.smsText {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 18px;
  display: flex;
  align-items: center;
  color: rgba(0, 0, 0, 0.87);
  padding-left: 14px;
}

.smsTimeStamp {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 12px;
  display: flex;
  align-items: center;
  text-align: right;
  color: rgba(0, 0, 0, 0.54);
  position: absolute;
  right: 0;
  margin-top: -10px;
}
