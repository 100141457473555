.modalOverlay {
  display: flex;
  align-items: center;
  justify-content: center;

  position: absolute;
  top: 30px;
  left: 0;

  width: 100%;
  height: 100%;

  z-index: 1;
}

.modalContent {
  width: 733px;
  height: fit-content;
  max-height: 100%;
  overflow-y: auto;
  text-align: center;
  padding: 24px 22px 0px;

  border-radius: 8px;
  background-color: #ffffff;

  font-family: Arial, Verdana, Tahoma, sans-serif;
}

.title {
  font-family: 'Arial';
  font-style: normal;
  font-weight: 400;
  font-size: 24px;
  line-height: 28px;
  /* identical to box height */

  display: flex;
  align-items: center;

  color: #666666;
}

.button {
  margin: 40px 40px 20px 40px;
  width: 100%;
  height: 39px;

  text-transform: none;
  letter-spacing: 0px;

  color: #ffffff;
  --background: #37d6ba;
}

.dropdown {
  width: 100%;
  z-index: 999;
  margin-top: 2px;
  text-align: left;
  border-bottom: 1px solid #dedede;
}
