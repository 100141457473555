.page {
  display: flex;
  width: 100%;
  height: 100%;
  background-color: #f5f5f5;

  padding: 20px 42px 57px 34px;
}

.tablesWrapper {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}

.employeeSurveysWrapper {
  margin-top: 65px;
}
