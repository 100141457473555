.loader {
  margin: 100px auto;
}

.pageWrapper {
  width: 100%;
  background-color: #f5f5f5;
  padding: 20px 42px 57px 34px;
}

.formsLibraryPageWrapper {
  display: flex;
  justify-content: space-between;
  width: 100%;
  background-color: #f5f5f5;
  padding: 20px 42px 57px 34px;
}

.formsListWrapper {
  width: 577px;
  height: 70%;

  /* Neutral / White */

  background: #ffffff;
  /* drop shadow */

  box-shadow: 0px 12px 26px rgba(16, 30, 115, 0.06);
  border-radius: 8px;

  .formsListTitle {
    padding-top: 16px;
    font-family: Arial;
    font-style: normal;
    font-weight: bold;
    font-size: 24px;
    line-height: 28px;
    margin: 16px 0 0 16px;
    /* identical to box height */

    display: flex;
    align-items: center;

    /* Dark Color */

    color: rgba(0, 0, 0, 0.87);
  }

  .formsListDescription {
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 16px;
    margin: 16px 0 0 16px;

    /* Gray · 80% */

    color: #5e6366;
  }

  .formsSearch {
    background: #f4f4f4;
    border-radius: 6px;
    height: 45px;
    width: 312px;
    margin: 16px 0 0 16px;
    padding-left: 20px;
  }

  .formsList {
    margin-top: 15px;
    width: auto;
    height: 57vh;
    padding: 0 15px 0 15px;
    overflow-y: auto;

    .formLink {
      color: #367bf5;
      text-decoration: underline;
      cursor: pointer;
    }

    .formContainer {
      display: flex;
      justify-content: space-between;
      align-items: center;
      border-bottom: 0.2px solid rgb(230, 227, 227);

      .formItemShare {
        display: flex;
        flex-direction: row;
        align-items: center;
        min-width: 300px;
        justify-content: space-between;

        .formItem {
          max-width: 360px;
        }
      }
    }
  }
}

.newFormButton {
  width: 307px;
  height: 356px;
  margin: auto;
  text-transform: none;

  background: #dcf4fe;
  /* drop shadow */

  box-shadow: 0px 12px 26px rgba(16, 30, 115, 0.06);
  border-radius: 16px;

  .addFormIcon {
    width: 240px;
    margin-left: 10px;
    pointerevents: 'none';
  }

  .newFormText {
    font-family: Arial;
    font-style: normal;
    font-weight: bold;
    font-size: 24px;
    line-height: 28px;
    color: rgba(0, 0, 0, 0.87);
    margin-left: 5px;
  }
}

.formCreator {
  height: 100vh;
  border: 1px solid rgba(0, 0, 0, 0.07);
  box-sizing: border-box;
}

//.formCreatorHeader {
//  position: relative;
//  height: 68px;
//  width: 100%;
//  background: #ffffff;
//  border: 1px solid rgba(0, 0, 0, 0.07);
//  box-sizing: border-box;
//}

.editButton {
  width: 198px;
  height: 34px;
  position: absolute;
  left: 20px;
  top: 15px;
}

.previewButton {
  width: 198px;
  height: 34px;
  position: absolute;
  right: 20px;
  top: 15px;
}

form {
  margin-top: 0 !important;
}

.analyticsContainer {
  padding: 32px;
  overflow-wrap: break-word;
}

.newFormContainer {
  display: flex;
  flex-direction: column;
  padding: 20px 42px 57px 34px;
}

.submissionContainer {
  display: block;
  clear: both;
}

.tabsContainer {
  width: 100%;
}

.tabList {
  width: 577px;
  display: flex;
  justify-content: flex-end;
  margin-bottom: -20px;
}

.tabItem {
  border: 0;
  width: 100px;
  text-align: center;
  cursor: pointer;
  padding: 20px 0 10px 0;
}

.tableWrapper {
  min-width: 1264px;
  width: 100%;

  border-radius: 8px;
  background: #ffffff;
  box-shadow: 0px 12px 26px rgba(16, 30, 115, 0.06);
}

.tableTopRow {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 68px;
  padding: 0 25px 0 13px;
}

.searchBar {
  flex: 0 1 auto;
  width: 25%;
  background: #f4f4f4;
  margin: 24px;
  padding: 8px;
}

ion-item.search {
  width: 312px;

  border-radius: 6px;
  --background: #f4f4f4;
  border: none;
}

.newItemButton {
  margin: 0;
  width: 223px;
  height: 45px;

  text-transform: none;
  letter-spacing: 0px;

  color: #ffffff;
  --background: #0a3860;
}

.loaderContainer {
  display: flex;
  flex: 1 1 auto;
  overflow: auto;
}

.error {
  text-align: center;
  height: 100px;
  margin: 50px;
}

.chartsContainer {
  width: 100%;
}

.gridContent {
  display: grid;
  overflow: auto;
  grid-template-columns: 1fr 1fr;
  grid-gap: 20px;
  width: 100%;
}

.uploadList {
  --border-style: solid;
  --border-width: 2px;
  --border-color: #000000;
  margin: 0;
  height: 45px;
  width: 50%;

  font-family: Arial, sans-serif;
  font-weight: bold;
  font-size: 18px;
  line-height: 21px;

  text-transform: none;
  letter-spacing: 0px;

  color: #000000;
  --background: white;
}

.uploadIcon {
  margin-left: 10px;
}

div.range-slider {
  position: relative;
  width: 200px;
  height: 35px;
  text-align: center;
}

div.range-slider input {
  pointer-events: none;
  position: absolute;
  overflow: hidden;
  left: 0;
  top: 30px;
  width: 200px;
  outline: none;
  height: 18px;
  margin: 0;
  padding: 0;
}

div.range-slider input::-webkit-slider-thumb {
  pointer-events: all;
  position: relative;
  z-index: 1;
  outline: 0;
}

div.range-slider input::-moz-range-thumb {
  pointer-events: all;
  position: relative;
  z-index: 10;
  -moz-appearance: none;
  width: 9px;
}