.headerText {
  font-family: Arial;
  font-style: normal;
  font-weight: normal;
  font-size: 24px;
  line-height: 28px;

  display: flex;
  align-items: center;

  color: #666666;
}

.descText {
  font-family: Arial;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 16px;

  display: flex;
  align-items: center;
  color: #999999;
}

.fileUplaodContainer {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: 130px;
  margin: 20px 0 40px 0;
  width: 100%;
  background: rgba(220, 244, 254, 0.25);
  border: 1px dashed rgba(9, 57, 95, 0.5);
  border-radius: 24px;
}

.fileUploadInput {
  font-size: 70px;
  position: absolute;
  left: 0;
  top: 0;
  opacity: 0;
}

.circle {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 60px;
  height: 60px;
  background: #dcf4fe;
  border: 1px dashed #0a3860;
  box-sizing: border-box;
  border-radius: 50%;
}

.icon {
  text-align: center;
  height: 30px;
  width: 30px;
}

.logoplaceholderLabel {
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  display: flex;
  align-items: center;
  margin-top: 10px;
  color: #09395f;
}

.termsText {
  height: 50vh;
  overflow-y: scroll;
  width: 70%;
  text-align: left;
  margin: 15px 0 30px 0;
  padding-right: 20px;
}

.button {
  --background: #37d6ba;
}

.errorMessage {
  top: 10px;
  font-size: 13px;
  color: red;
}

.successText {
  width: 100%;
  font-family: Arial;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  text-align: left;
  display: flex;
  align-items: flex-start;
  color: #999999;
  margin: 20px 0;
}

.form {
  margin-top: 0;
  width: 100%;
}

.container {
  margin-top: 50px;
  padding-top: 20px;
  border-top: 2px dashed #aaa;
}

.addServiceContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 20px;

  p {
    margin-left: 10px;
    font-weight: bold;
  }
}

.ionRowFormContainer {
  position: relative;
  height: 500px;
  overflow-y: scroll;
  scroll-behavior: smooth;
}
