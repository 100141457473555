.container {
  height: 100vh;

  div {
    height: 100vh;
    width: 50vw;
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  p {
    color: #464646;
    font-size: 20px;
  }
}

.errorTitle {
  color: #09395f;
  font-size: 35px;
  font-weight: bold;
}

.errorDetails {
  padding: 20px;
}

.errorIcon {
  height: 200px;
  width: 200px;
}

.nextButton {
  margin-top: 50px;
  color: #fff;
  width: 250px;
  --background: #09395f;
}
