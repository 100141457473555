@font-face {
  font-family: 'Lato';
  src: url('../assets/fonts/Lato/Lato-Regular.ttf');
}

.pageContainer {
  width: 100%;
  padding: 20px;
  background-color: #f5f5f5;
}
.pageTitle {
  font-family: 'Lato', sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  color: #25282b;
}

.pageDesc {
  font-style: italic;
  font-weight: normal;
  font-size: 16px;
  line-height: 18px;
  color: #919699;
}

.container {
  padding: 20px 10px;
}
.stepRow {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.circle {
  width: 48px;
  height: 48px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  background-color: #fff;
  border: 2px solid #09395f;
  font-weight: bold;
}

.stepTitle {
  font-family: 'Lato', sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  margin-left: 20px;
  color: #25282b;
}

.content {
  display: flex;
  flex-direction: row;
  align-items: stretch;
  width: 100%;
}

.stepContent {
  display: flex;
  flex-direction: row;
  align-items: stretch;
  width: 100%;
}

.stepLine {
  height: 100%;
  width: 2px;
  background-color: #09395f;
  margin-left: 23px;
}

.stepNext {
  padding-bottom: 20px;
  margin: 0 20px 30px 20px;
}

.nextButton {
  color: #fff;
  float: right;
  --background: #09395f;
}

.messageView {
  width: 100%;
  margin-left: 30px;
  margin-bottom: 30px;
}

.inputInfo {
  font-style: italic;
  font-weight: normal;
  font-size: 12px;
  line-height: 16px;
  color: #5e6366;
  margin: 5px 10px;
}

.tableView {
  margin-left: 40px;
  margin-bottom: 30px;
  width: 100%;
}

.buttonContainer {
  margin: 20px 10px 20px 60px;
}

.sendButton {
  color: #fff;
  --background: #09395f;
}

.filters {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  border: 1px solid #c6cacc;
  border-radius: 5px;
  padding: 10px;
  margin: 10px;

  ion-icon {
    font-size: 14px;
  }
}

.filtersContainer {
  display: flex;
  justify-content: flex-end;
  background-color: #ffff;
}

.filterItem {
  border-radius: 4px;
  margin: 10px;
}

.select {
  width: 250px;
}

.errorMessage {
  color: red;
}

.tabsContainer {
  background-color: #fff;
}

.tabList {
  width: 100%;
  display: flex;
  padding: 15px 15px 0 15px;
}

.tabItem {
  border: 0;
  width: 150px;
  text-align: center;
  cursor: pointer;
  padding: 10px;
}

.nthTab {
  border-left: 1px solid #eee;
}

.selectedTab {
  border-bottom: 3px solid #09395f;
  color: #09395f;
  font-weight: bold;
}
