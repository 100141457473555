.section {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex-basis: 481px;
  height: fit-content;
  min-height: 530px;
  min-width: 425px;
  border-radius: 8px;

  background: #ffffff;
  box-shadow: 0px 12px 26px 0px rgba(16, 30, 115, 0.06);
}

.sectionTitle {
  font-family: Lato, sans-serif;
  font-size: 20px;
  font-weight: 700;
  line-height: 26px;
  letter-spacing: 0.2px;

  color: #25282b;
}

.userInformationSectionTitle {
  padding: 17px 29px 12px;
}

.userIdentityWrapper {
  display: flex;
  padding: 18px 17px 23px 52px;
  align-items: center;

  background: #f9f9f9;
}

.userAvatar {
  min-width: 82px;
  min-height: 82px;
}

.userIdentity {
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  justify-content: center;
  margin-left: 12px;
}

.userName {
  margin-top: 8px;

  font-family: Arial, Helvetica, sans-serif;
  font-weight: bold;
  font-size: 21px;
  line-height: 21px;
}

.userType {
  margin-top: 1px;

  font-family: Arial, Helvetica, sans-serif;
  font-weight: normal;
  font-size: 17px;
  line-height: 21px;
}

.editProfileWrapper {
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;

  font-family: Arial, Helvetica, sans-serif;
  font-size: 12px;
  font-variant: normal;
}

ion-icon[name='pencil'].editProfileIcon {
  margin-right: 7px;

  align-self: center;
  background-image: url(./icons/pencil.svg);
  background-repeat: no-repeat;
  background-position: center;
  background-size: 18px;
  width: 18px;
  height: 18px;
}

.editProfileButton {
  position: absolute;
  right: 0;
  bottom: -20px;

  height: 46px;
  margin: 0;
  // assumes ionic-button padding (visible on hover)
  margin-right: -9px;
  padding: 0;

  font-family: Arial, Helvetica, sans-serif;
  font-size: 12px;
  line-height: 14px;
  font-variant: normal;

  letter-spacing: 0;
  text-transform: none;
}

.userContacts {
  padding: 33px 52px;
  line-height: 46px;
}

.userContactRow {
  display: flex;
  & + & {
    margin-top: 22px;
  }
}

.userRoleRow {
  display: flex;
  align-items: center;
  margin: 10px 0 10px 0;
  & + & {
    margin-top: 22px;
  }
}

.userContactKey {
  font-family: Arial, Helvetica, sans-serif;
  font-weight: bold;
  font-size: 20px;
  line-height: 24px;
  height: 100%;
}

.userContactValue {
  padding-left: 5px;
  font-family: Arial, Helvetica, sans-serif;
  font-weight: normal;
  font-size: 20px;
  line-height: 24px;
}

.spacer {
  padding: 32px;
}

.errorMessage {
  font-family: Arial, Helvetica, sans-serif;
  color: red;
}

.userAction {
  padding: 52px;
  cursor: pointer;
}

.userTerminateButton {
  font-family: Arial, Helvetica, sans-serif;
  font-weight: normal;
  font-size: 20px;
  text-decoration: underline;
}

.msgReview {
  display: flex;
  align-items: center;
  flex-direction: row;
  text-decoration: underline;
  cursor: pointer;
  color: #397cf4;
}

.msgReviewIcon {
  cursor: pointer;
  color: #000000;
}

.emailSection {
  margin-top: 5%;
  flex-direction: column;
  justify-content: space-between;
  flex-basis: 481px;
  height: fit-content;
  min-height: 281px;
  border-radius: 8px;

  background: #ffffff;
  box-shadow: 0px 12px 26px 0px rgba(16, 30, 115, 0.06);
}

.emailContainer {
  padding-bottom: 8.5px;
}
.emailWrapper:last-child {
  padding: 5px 43px 93px 32px;
}
.emailWrapper {
  padding: 5px 43px 5px 32px;
}

.emailIdentityWrapper {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
}

.emailContacts {
  padding: 3px 10px;
  line-height: 10px;
}

.emailContactKey {
  flex: 2;
  font-family: Arial, Helvetica, sans-serif;
  font-weight: bold;
  font-size: 16px;
  line-height: 18.75px;
}

.emailContactRow {
  display: flex;
  & + & {
    margin-top: 5px;
  }
}

.emailContactValue {
  font-family: Arial, Helvetica, sans-serif;
  font-weight: normal;
  font-size: 16px;
  line-height: 19px;
}

.emailCheckBox {
  min-height: 18px;
  min-width: 18px;
  margin-left: 0;
}
