.table {
  position: relative;
  margin: auto;
  padding: 8px;
  width: 100%;
  flex: auto;

  /* Neutral / White */

  background: #ffffff;
  /* card / floating */

  box-shadow: 0px 12px 26px rgba(16, 30, 115, 0.06);
  border-radius: 8px;

  .checkboxContainer {
    display: flex;
    flex-direction: row;
    align-items: center;

    p {
      margin-left: 10px;
    }
  }

  thead {
    position: relative;
    text-align: left;
    /* Neutral / Divider */

    background: #e8e8e8;
    border-top: 1px solid #e8e8e8;

    tr {
      height: 56px;

      & .icon {
        margin-left: 12px;
        & img {
          display: inline-block;
          height: 20px;
          width: 20px;
        }
      }

      th {
        position: relative;
        padding: 16px;

        font-family: Lato;
        font-style: normal;
        font-weight: bold;
        font-size: 12px;
        line-height: 20px;
        /* identical to box height, or 167% */

        white-space: nowrap;
        vertical-align: top;

        letter-spacing: 0.1px;

        white-space: nowrap;
        vertical-align: top;

        /* Neutral / Black */

        color: #25282b;

        &:first-of-type {
          padding-left: 39px;
        }

        b {
          vertical-align: top;
        }
      }

      td {
        background-color: #fff;

        span[class='table-filters'] {
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          align-items: center;
          border: 1px solid #c6cacc;
          border-radius: 5px;
          padding: 6px 10px;
          margin: 10px;

          ion-icon {
            font-size: 14px;
          }
        }
      }
    }
  }

  tbody {
    tr {
      border-top: 1px solid #e8e8e8;

      &.link {
        cursor: pointer;
        &:hover {
          background: lightgray;
        }
      }

      td {
        padding: 16px;
        position: relative;

        font-family: Lato;
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        line-height: 20px;
        /* identical to box height, or 143% */

        letter-spacing: 0.1px;

        /* Neutral / Gray dark */
        color: #52575c;

        &:first-of-type {
          padding-left: 39px;
        }
      }
    }
  }
}

.checkbox[type='checkbox'] {
  height: 15px;
  width: 15px;
  background: url('../assets/icon/checkbox_unchecked.png') no-repeat 0 0;
}

.checkbox[type='checkbox']:checked {
  height: 15px;
  width: 15px;
  background: url('../assets/icon/checkbox_checked.png') no-repeat 0 0;
}
