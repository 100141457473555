.loader {
  margin: 100px auto;
}

.pageWrapper {
  display: flex;
  width: 100%;
  height: 100%;
  background-color: #f5f5f5;
  padding: 20px 42px 57px 34px;
}

.tabsContainer {
  width: 100%;
}

.tabList {
  width: 100%;
  display: flex;
  justify-content: flex-end;
}

.tabItem {
  border: 0;
  width: 100px;
  text-align: center;
  cursor: pointer;
  padding: 10px 0 10px 0;
}

.tableWrapper {
  min-width: 1264px;
  width: 100%;

  border-radius: 8px;
  background: #ffffff;
  box-shadow: 0px 12px 26px rgba(16, 30, 115, 0.06);
}

.tableTopRow {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 68px;
  padding: 0 25px 0 13px;
}

.searchBar {
  flex: 0 1 auto;
  width: 25%;
  background: #f4f4f4;
  margin: 24px;
  padding: 8px;
}

ion-item.search {
  width: 312px;

  border-radius: 6px;
  --background: #f4f4f4;
  border: none;
}

.newItemButton {
  margin: 0;
  width: 223px;
  height: 45px;

  text-transform: none;
  letter-spacing: 0px;

  color: #ffffff;
  --background: #0a3860;
}

.loaderContainer {
  display: flex;
  flex: 1 1 auto;
  overflow: auto;
}

.error {
  text-align: center;
  height: 100px;
  margin: 50px;
}
