.listItem {
  transition: height ease 0.2s;

  --ripple-color: transparent;
  --background-activated: transparent;
  --background-activated-opacity: transparent;
  --background-focused: transparent;
  --background-focused-opacity: transparent;
  --background-hover: transparent;
  --background-hover-opacity: transparent;
}
