.sign-in-img {
  position: fixed;
  max-width: 100%;
  max-height: 100vh;
  height: auto;
}
.logo {
  display: inline-flex;
  width: 42%;
  margin-top: 8vh;
  margin-bottom: 2.5vh;
}

#login-text {
  font-family: Arial;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  color: #999999;
}

#login-title {
  font-family: Arial;
  font-style: normal;
  font-weight: 400;
  font-size: 24px;
  line-height: 28px;
  color: #666666;
}

#login-subtitle {
  font-family: Arial;
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 28px;
  color: #999999;
}

form {
  margin-top: 8vh;
}

ion-icon {
  color: #c4c4c4;
}

.login-divider {
  max-height: 1px;
  --background: #c4c4c4;
  min-height: 1px;
}

.divider-text {
  transform: translateY(-10px);
}

.sign-up-title {
  font-family: Arial;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 16px;
  color: #000000;
}

.sign-up-text {
  margin-left: 1rem;
  font-family: Arial;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 18px;
  color: #57cedd;
}

ion-button {
  margin-bottom: 1.5rem;
}
