.avatarStatusWrapper {
  position: relative;
}

.avatarStatusRing {
  position: absolute;
  bottom: 0;
  right: 0;
  width: 20px;
  height: 20px;

  background: white;
  border-radius: 50%;
}

.avatarStatus {
  position: absolute;
  bottom: 2px;
  right: 2px;
  width: 20px;
  height: 20px;
  border-radius: 50%;

  border: 3px solid #ffffff;

  &_active {
    background-color: #5bd439;
  }
  &_away {
    background-color: #ffaf25;
  }
  &_busy {
    background-color: #ff4747;
  }
  &_unknown {
    background-color: #aaaaaa;
  }
}
