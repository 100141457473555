.modalOverlay {
  display: flex;
  align-items: center;
  justify-content: center;

  position: absolute;
  top: 0px;
  left: 0;

  width: 100%;
  height: 100%;

  z-index: 1;
}

.modalContent {
  position: relative;
  width: fit-content;
  height: fit-content;
  max-height: 100%;
  overflow-y: auto;

  border: 1px solid #c6cacc;
  border-radius: 8px;
  background-color: #ffffff;
}

.closeCross {
  position: absolute;
  top: 5px;
  right: 13px;
  padding: 13px;

  font-size: 38px;
  color: #000000;
  cursor: pointer;
}

.wrapper {
  max-width: 577px;
  padding: 42px 38px 0 52px;
}

.title {
  font-size: 24px;
  line-height: 28px;
  font-weight: 700;
  color: black;
}

.info {
  margin-top: 16px;
  max-width: 475px;
  margin-bottom: 32px;
  font-size: 18px;
  line-height: 21px;

  color: #5e6366;
}

.form {
  margin-top: 43px;
}

.eventContainer {
  display: flex;
  justify-content: space-between;
}

.requiredMark {
  color: #ff00008a;
}

.csvDownload{
  font-weight: bolder;
  cursor: pointer;
}

// requiredServices

.requiredServicesTitle,
.uploadListTitle {
  color: #1b1b1b;
  font-family: Roboto;
  font-size: 16px;
  line-height: 18px;
}

.uploadListInfo {
  margin-bottom: 27px;
}


.requiredServicesInfo,
.uploadListInfo {
  margin-top: 4px;

  color: #6d6d6d;
  font-family: Roboto;
  font-size: 12px;
  line-height: 12px;
}

.requiredServicesBody {
  padding: 0 8px 0 8px;
  margin-top: 32px;
}

.requiredServicesColumn {
  flex-grow: 1;
  display: grid;
  row-gap: 8px;
  grid-template-columns: 1fr 1fr;
  margin-left: -16px;
  margin-right: -16px;
}

.requiredServiceType {
  font-family: Roboto;
  font-weight: bold;
  font-size: 14px;
  padding-left: 16px;
  line-height: 19px;
}

.requiredServiceOption {
  align-self: flex-start;

  ion-item {
    --padding-start: 16px;

    --highlight-height: 38px;
    --min-height: 38px;
    line-height: 38px;
  }

  ion-label,
  ion-checkbox {
    --color: #1b1b1b !important;
    --background: #f7f9fa;
    --border-color: #323232;
    --background-checked: #323232;
    --border-color-checked: #323232;
    margin: 2px 0;
  }

  ion-checkbox {
    margin-right: 12px;
  }
}

.separator {
  margin: 19px 7px 0;
  height: 1px;
  background: #c4c4c4;
}

// buttons

.buttons {
  margin: 23px 6px 0;
}

.confirmButton {
  color: #fff;
  text-transform: none;
  letter-spacing: 0;
  --background: #0a3860;

  --border-width: 2px;
  --border-style: solid;
  --border-color: #0a3860;
  --box-shadow: none;
  width: 474px;
}

.formsListWrapper {
  width: 577px;
  height: 70%;

  /* Neutral / White */

  background: #ffffff;
  /* drop shadow */

  box-shadow: 0px 12px 26px rgba(16, 30, 115, 0.06);
  border-radius: 8px;

  .formsListTitle {
    padding-top: 16px;
    font-family: Arial;
    font-style: normal;
    font-weight: bold;
    font-size: 24px;
    line-height: 28px;
    margin: 16px 0 0 16px;
    /* identical to box height */

    display: flex;
    align-items: center;

    /* Dark Color */

    color: rgba(0, 0, 0, 0.87);
  }

  .formsListDescription {
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 16px;
    margin: 16px 0 0 16px;

    /* Gray · 80% */

    color: #5e6366;
  }

  .formsSearch {
    background: #f4f4f4;
    border-radius: 6px;
    height: 45px;
    width: 312px;
    margin: 16px 0 0 16px;
    padding-left: 20px;
  }

  .formsList {
    margin-top: 15px;
    width: 500px;
    height: 75%;
    overflow-y: auto;

    .formLink {
      color: #367bf5;
      text-decoration: underline;
      cursor: pointer;
    }

    .formContainer {
      display: flex;
      justify-content: space-between;
      border-bottom: 0.2px solid rgb(230, 227, 227);
    }
  }
}

ion-progress-bar {
  --buffer-background: #E0E0E0;
  --progress-background: #0a3860;
;
}

.dangerBar {
  --progress-background: #DD0000;
  height: 8px;
  margin: 1.5% 0;
}

.checkbox-icon {
  border-radius: 50%!important
}