.wrapper {
}

.zone {
  width: 100%;
  height: 109px;
  background: rgba(189, 235, 250, 0.25);
  border: 1px dashed #09395f;
  border-radius: 8px;
  border-width: 1px;
  transition: background 0.2s ease-in;

  cursor: pointer;

  &:hover,
  &.zoneActive {
    background: rgba(189, 235, 250, 0.5);
  }
}

.icon {
  text-align: center;
  margin: 0 auto;
  margin-top: 17px;
}
.help {
  margin-top: 17px;
  text-align: center;
  display: flex;
  grid-column-gap: 8px;
  align-items: center;
  color: #6d6d6d;
  font-family: Roboto, sans-serif;
  font-size: 14px;
  line-height: 16px;
  justify-content: center;
}

.uploadedFiles {
  margin-top: 8px;
}

.uploadedFileName {
  font-family: Roboto, sans-serif;
  font-size: 14px;
  line-height: 14px;

  color: #6d6d6d;
}

.button {
  margin: 0;
  letter-spacing: 0;
  text-transform: none;
  font-family: Arial, Roboto, sans-serif;

  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 16px;
  text-align: center;
  --background: white;
  color: #09395f;
}
