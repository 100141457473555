.modalOverlay {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
}

.modalContent {
  width: fit-content;
  height: fit-content;
  max-height: 100%;
  overflow-y: auto;
  padding: 28px 32px;
  border: 1px solid #c6cacc;
  border-radius: 8px;
  background-color: #ffffff;
}

.closeCross {
  padding: 10px;
  font-size: 32px;
  color: #000000;
  cursor: pointer;
  margin-bottom: 6%;
}

.title {
  color: #000000;
  font-size: 24px;
  line-height: 28px;
  font-weight: 700;
  display: flex;
  align-items: center;
  width: 30rem;
}

.explanatoryText {
  margin-top: 32px;
  margin-bottom: 26px;
  color: #5e6366;
  font-size: 20px;
  line-height: 23px;
}

// buttons

.buttons {
  display: flex;
  justify-content: space-between;
  margin-top: 42px;
  border-top: 2px solid #eee;
}

.cancelButton,
.submitButton {
  text-transform: none;
  letter-spacing: 0;
  --background: white;
  --border-width: 1.5px;
  --border-style: solid;
  --box-shadow: none;
  margin-top: 4%;
}

.cancelButton {
  width: 97px;
  --border-radius: 4px;
  color: #aaaaaa;
}

.submitButton {
  width: 210px;
  color: white;
  background-color: #0a3860;
  --border-radius: 4px;
  --border-color: #0a3860;
  border-radius: 8px;
  --background: #0a3860;
}
