body::-webkit-scrollbar {
  width: 1em;
}
 
body::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}
 
body::-webkit-scrollbar-thumb {
  background-color: darkgrey;
  outline: 1px solid slategrey;
}

.page {
  display: flex;
  width: 100%;
  height: fit-content;

  padding: 34px;

  background-color: #e5e5e5;

  z-index: 1;
}

.tablesWrapper {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}

.patientSurveysWrapper, .fileListWrapper {
  margin-top: 65px;
}

.patientSurveysWrapper, .fileListWrapper, .serviceRequestListWrapper {
  max-height: 500px;
  overflow-y: auto;

  thead {
    position: sticky !important;
    top: 0 !important;
    z-index: 1 !important;  
  }
}

