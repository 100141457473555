$sidebar-bg-color: #fff;
$sidebar-color: #000 !default;
$sidebar-width: 300px !default;
$sidebar-collapsed-width: 80px !default;
$icon-bg-color: #fff !default;
$icon-size: 40px !default;

@import '~react-pro-sidebar/dist/scss/styles.scss';

ion-icon {
  font-size: 24px;
  color: #0a3860;
}

.pro-sidebar {
  position: fixed;
  height: 100%;
  box-shadow: 6px 0px 8px rgba(0, 0, 0, 0.25);
}

.pro-sidebar-header {
  height: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-bottom-width: 0 !important;

  div {
    position: relative;
    width: 100%;
    padding-left: 20px;

    img {
      height: 40px;
    }
    span {
      position: absolute;
      padding: 10px;
      right: 0;
    }
  }
}
