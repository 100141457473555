.container {
  margin-top: 50px;
  padding-top: 20px;
  border-top: 2px dashed #aaa;
}

.dropdown {
  width: 100%;
  z-index: 999;
  margin-top: 2px;
  text-align: left;
  border-bottom: 1px solid #dedede;

  div[class^='-menu'] {
    z-index: 999;
  }
}

.title {
  margin-top: 35px;
  margin-left: 10px;
  font-style: normal;
  font-weight: 400;
  font-size: 24px;
  line-height: 28px;
  display: flex;
  align-items: center;

  color: #666666;
}

.subTitle {
  margin-top: 20px;
  margin-left: 10px;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;

  color: #999999;
}
