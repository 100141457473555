.modalOverlay {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    bottom: 10%;
    height: 80%;
    z-index: 1;
    max-width: 800px;
    width: 100%;
}

.modalContent {
    height: fit-content;
    max-height: 100%;
    overflow-y: auto;
    border: 1px solid #c6cacc;
    border-radius: 8px;
    background-color: #ffffff;
}

// common

.itemError {
  --border-color: red;
  --highlight-color-focused: red;
}

ion-label {
    --color: #5e6366 !important;
}

ion-datetime {
    --padding-bottom: 8px;
    --padding-top: 8px;
}

ion-icon {
    &[name='user'] {
        align-self: flex-end;
        background-image: url(./icons/user.svg);
        background-repeat: no-repeat;
        background-position: center;
        background-size: 20px 20px;
    }

    &[name='cake'] {
        align-self: flex-end;
        background-image: url(./icons/cake.svg);
        background-repeat: no-repeat;
        background-position: center;
        background-size: contain;
    }

    &[name='pencil'] {
        align-self: flex-end;
        background-image: url(./icons/pencil.svg);
        background-repeat: no-repeat;
        background-position: center;
        background-size: 18px;
    }

    &[name='pointOnMap'] {
        align-self: flex-end;
        background-image: url(./icons/pointOnMap.svg);
        background-repeat: no-repeat;
        background-position: center;
        background-size: 18px;
        width: 25px;
        height: 25px;
    }
}

.standartIcon {
    align-self: flex-end;
}

.wrapper {
    margin: 2rem auto;
    width: 85%;
    padding: 10px;
    display: flex;
    flex-direction: column;
}

.title {
    font-size: 24px;
    line-height: 28px;
    font-weight: 700;
}

.info {
    margin-top: 16px;
    font-size: 20px;
    line-height: 23px;
    color: #5e6366;
}

.infoHours {
    font-size: smaller;
    line-height: -10%;
    font-style: italic;
    color: #878787;
}

// patient

.patient {
    display: flex;
    flex-direction: column;
    row-gap: 24px;
    margin-top: 51px;
    & ion-item {
        border-top-left-radius: 4px;
        border-top-right-radius: 4px;
        --min-height: 20px;
        line-height: 20px;
    }
}

.organization {
    width: 100%
}

.patient .row {
    display: flex;
    column-gap: 2.5%;
    width: 100%;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-end;
    & .field {
        width: 45%;
        display: flex;
        & .ion-item {
            width: 100%;
        }
    }
}

.field {
    ion-item {
        width: 100%;
    }
}

.firstName {
    width: 50%;
}

.lastName {
    width: 50%;
}

.phone {
    flex-grow: 1;
}

.language {
    width: 50%;
}

.organization {
    width: 100% !important;
}

.requiredServicesTitle {
    color: #1b1b1b;
    font-family: Roboto;
    font-size: 16px;
    line-height: 18px;
    font-weight: bold;
}

.requiredServicesInfo {
    color: #6d6d6d;
    font-family: Roboto;
    font-size: 12px;
    line-height: 12px;
}

.requiredServicesBody {
    padding: 0 8px 0 8px;
    margin-top: 24px;
}

.requiredServicesColumn {
    flex-grow: 1;
    display: grid;
    row-gap: 8px;
    grid-template-columns: 1fr 1fr;
    margin-left: -16px;
    margin-right: -16px;
}

.requiredServiceType {
    font-family: Roboto;
    font-weight: bold;
    font-size: 14px;
    padding-left: 16px;
    line-height: 19px;
}

.requiredServiceOption {
    align-self: flex-start;
    ion-item {
        --padding-start: 16px;
        --highlight-height: 38px;
        --min-height: 38px;
        line-height: 38px;
    }

    ion-label, ion-checkbox {
        --color: #1b1b1b !important;
        --background: #f7f9fa;
        margin: 2px 0;
    }

    ion-checkbox {
        margin-right: 12px;
    }
}

// additionalNotes

.additionalNotesWrapper {
    margin-top: 16px;
    border-radius: 7px;
    overflow: hidden;
    border: 1px solid #dedede;
}

.additionalNotes {
    width: 100%;
    align-items: center;
    --border-width: 0;
}

.buttons {
    display: flex;
    justify-content: space-between;
    margin-top: 28px;
}

.cancelButton, .submitButton {
    color: #000000;
    text-transform: none;
    letter-spacing: 0;
    --background: white;
    --border-width: 2px;
    --border-style: solid;
    --border-color: #000000;
    --box-shadow: none;
}

.cancelButton {
    width: 45%;
}

.submitButton {
    width: 45%
}

.fileUplaod {
    ion-item {
        --inner-border-width: 0;
        --background: #fff;
    }

    ion-label {
        margin-left: 10px;
    }
}

.closeCross {
    position: absolute;
    top: 4px;
    right: 17px;
    padding: 24px;
    font-size: 38px;
    color: #000000;
    cursor: pointer;
}

.csvExportButton {
    margin: 0;
    width: 227px;
    height: 39px;
    text-transform: none;
    letter-spacing: 0px;
    color: #ffffff;
    --background: #0a3860;
}

.smsTitle {
    font-size: 24px;
    line-height: 28px;
    font-weight: 700;
    padding-top: 32px;
}

.smsWrapper {
    height: 400px;
    overflow-y: auto;
    margin: 32px 0;
}

.profileImage {
    font-size: 32px;
}

.smsText {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 18px;
    display: flex;
    align-items: center;
    color: rgba(0, 0, 0, 0.87);
    padding-left: 14px;
}

.smsTimeStamp {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 12px;
    display: flex;
    align-items: center;
    text-align: right;
    color: rgba(0, 0, 0, 0.54);
    position: absolute;
    right: 0;
    margin-top: -10px;
}

.assignEmployeeTitle {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    margin: 10px 15px 0 0;
    color: rgba(0, 0, 0, 0.87);
}

.assignEmployeeSubTitle {
    width: 100%;
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 12px;
    color: rgba(0, 0, 0, 0.54);
    margin: 10px 15px 15px 0;
}

.selectEmployee {
    width: 100% !important;
}

.PhoneInputCountrySelect {
    padding: 0;
}
