.modalOverlay {
  display: flex;
  align-items: center;
  justify-content: center;

  position: absolute;
  top: 30px;
  left: 0;

  width: 100%;
  height: 100%;

  z-index: 1;
}

.modalContent {
  width: fit-content;
  max-width: 362px;
  height: fit-content;
  max-height: 100%;
  overflow-y: auto;
  text-align: center;
  padding: 24px 22px 0px;

  border: 1px solid #c6cacc;
  border-radius: 8px;
  background-color: #ffffff;

  font-family: Arial;
}

.title {
  color: #000000;
  font-size: 24px;
  line-height: 28px;
  font-weight: 700;
}

.successImage {
  margin-top: 32px;

  svg {
    margin: 0 auto;
  }
}

.circle {
  animation: scaleRadiusToMax 0.3s cubic-bezier(0.1, -0.6, 0.2, 0) 1 forwards;
}

@keyframes scaleRadiusToMax {
  0% {
    r: 0;
  }

  100% {
    r: 96.5321;
  }
}

.checkmark {
  stroke-dasharray: 128;
  stroke-dashoffset: 128;

  animation: stroke 0.3s cubic-bezier(0.65, 0, 0.45, 1) 0.5s forwards,
    scale 0.3s ease-in-out 0.5s both;
}

@keyframes scale {
  0%,
  100% {
    transform: none;
  }

  50% {
    transform: scale3d(1.1, 1.1, 1) translateX(-10%) translateY(-10%);
  }
}

@keyframes stroke {
  100% {
    stroke-dashoffset: 0;
  }
}

.explanatoryText {
  margin-top: 32px;

  color: #5e6366;
  font-size: 20px;
  line-height: 23px;
}

.buttons {
  margin-top: 26px;
}

.closeButton {
  width: 173px;
  color: #000000;
  text-transform: none;
  --background: white;

  --border-width: 2px;
  --border-style: solid;
  --border-color: #000000;
  --box-shadow: none;
}
