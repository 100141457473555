.pageWrapper {
  overflow-y: scroll;
  width: 100%;
  margin-left: 80px;
  height: 100%;
  background-color: #f5f5f5;
}

.name {
  box-sizing: border-box;
  color: black;
  margin: 0;
  padding: 24px;
  width: 100%;
}

.content {
  display: flex;
  flex: 1;
  min-height: 0;
}

.topBar {
  align-items: center;
  background: lightgray;
  border-bottom: 1px solid gray;
  box-sizing: border-box;
  display: flex;
  flex: 0 0 auto;
  min-height: 77px;
  //padding: 8px 24px;
  width: 100%;
}
