.section {
  min-width: 742px;
  height: fit-content;

  margin-left: 33px;
  border-radius: 8px;

  background: #ffffff;
  box-shadow: 0px 12px 26px 0px rgba(16, 30, 115, 0.06);
}

.sectionTitle {
  padding: 8px 11px 8px 34px;

  font-family: Lato, sans-serif;
  font-size: 20px;
  font-weight: 700;
  line-height: 26px;
  letter-spacing: 0.2px;

  color: #25282b;
}

.serviceRequestsTitle {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.newServicesRequestButton {
  margin: 0;
  width: 227px;
  height: 39px;

  text-transform: none;
  letter-spacing: 0px;

  color: #ffffff;
  --background: #0a3860;
}

.serviceRequestView {
  color: #367bf5;
  text-decoration: underline;

  cursor: pointer;
}
