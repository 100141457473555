.pageContainer {
  padding: 20px;
  width: 100%;
}
.pageTitle {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 26px;
  text-transform: uppercase;
}

.pageDesc {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  color: #919699;
}

.container {
  padding: 20px 10px;
}

.card {
  padding-top: 10px;
  color: black;
  border-radius: 16px;
}
.cardHeader {
  color: #52575c;
  text-decoration: underline;

  font-family: 'Arial';
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 23px;
}
.cardSubtitle {
  color: #52575c;
  font-size: 12px;
  margin-left: 15px;
}
.completedIcon {
  color: #09395f;
  background: #dcf4fe;
  width: 25px;
  height: 25px;
  padding: 10px;
  border-radius: 50%;
}

ion-progress-bar {
  --buffer-background: #e0e0e0;
  --progress-background: #27ae60;
}
