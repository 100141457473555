.loader {
  margin: 100px auto;
}

.pageWrapper {
  display: flex;
  width: 100%;
  height: fit-content;

  padding: 20px 42px 57px 34px;
}

.tableWrapper {
  min-width: 1264px;
  width: 100%;

  border-radius: 8px;
  background: #ffffff;
  box-shadow: 0px 12px 26px rgba(16, 30, 115, 0.06);
}

.tableTopRow {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 68px;
  padding: 0 25px 0 13px;
}

.searchBar {
  flex: 0 1 auto;
  width: 25%;
  background: #f4f4f4;
  margin: 24px;
  padding: 8px;
}

.uploadList {
  --border-style: solid;
  --border-width: 2px;
  --border-color: #000000;
  margin: 0;
  height: 45px;

  font-family: Arial, sans-serif;
  font-weight: bold;
  font-size: 18px;
  line-height: 21px;

  text-transform: none;
  letter-spacing: 0px;

  color: #000000;
  --background: white;
}

.uploadIcon {
  margin-left: 10px;
}

.error {
  text-align: center;
  margin: 50px;
}

.downloadSurveys {
  --border-style: solid;
  --border-width: 2px;
  --border-color: #000000;

  margin: 0 10px 0 0;
  width: 227px;
  height: 45px;

  font-family: Arial, sans-serif;
  font-weight: bold;
  font-size: 18px;
  line-height: 21px;

  text-transform: none;
  letter-spacing: 0px;

  color: #ffffff;
  --background: #0a3860;
}
