.previewContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.image {
  height: 80px;
  border: 1px solid #ddd;
  border-radius: 5px;
}

.icon {
  height: 80px;
  width: 80px;
}

.fileName {
  margin-left: 10px;
}

.fileUpload {
  margin-left: 10px;
  background-color: #fff;
  padding: 10px;
  border-radius: 50px;
}
