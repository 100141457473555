.container {
  height: 100vh;
  display: flex;
  flex-direction: column;
}

.healthOpxLogo {
  margin-top: 28px;
  height: 63px;
}

.maintenanceLogo {
  flex: 1;
}

.row {
  flex: 1;
  align-items: center;
}

.textCol {
  flex: 2;
  margin-left: 170px;
}

.title {
  text-align: start;
  font-weight: 700;
  font-size: 18px;
  line-height: 21px;
  color: #0a3860;
}

.subTitle {
  margin-top: 16px;
  font-family: 'Lato';
  font-weight: 900;
  font-size: 60px;
  line-height: 72px;
  width: 100%;
  color: #09395f;
}

.content {
  width: 520px;
  margin-top: 40px;
  font-style: normal;
  font-weight: 400;
  font-size: 28px;
  line-height: 32px;

  color: #999999;
}

.button {
  margin-top: 20px;
  width: 470px;
  height: 45px;

  text-transform: none;
  letter-spacing: 0px;

  color: #ffffff;
  --background: #0a3860;
}
